import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Header from "./components/Shared/Header";
import { UserContext } from "./context/UserContextProvider";
import Login from "./pages/login";
import Chat from "./pages/chat";
import Chatv2 from "./pages/chatv2";
import PujaAllBooking from "./pages/pujaAllBooking"
import ChatOrders from "./pages/chatOrders";
import CallOrders from "./pages/callOrders";
import { AddQuestion } from "./pages/addQuestion";
import ContactInquiries from "./pages/contactInquires/contactInquires";
import ContactDetails from "./pages/contactInquires/contactDetails";
import PujaRecommendationList from "./pages/pujaRecommendation";
import Customers from "./pages/customers";
import Respondercallhistrory from "./pages/respondercallhistory";
import ChadhawaList from "./pages/chadhawaList";
import ManokamnaList from "./pages/manokamnaList";
import AstrologerList from "./pages/astrologerList";
import ReportsInquiry from "./pages/reportsInquiry";

const RequireAuth = () => {
  const { token } = useContext(UserContext);
  if (!token) {
    return <Navigate to={`/login?redirect=${window.location.pathname}`} />;
  }
  return <Outlet />;
};

const AppRouter = () => {
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<RequireAuth />}>
          {/* <Route path="/" element={<Chat />}></Route> */}
          <Route path="/" element={<Chatv2 />}></Route>
          <Route path="/pujaAllBooking" element={<PujaAllBooking />}></Route>
          <Route path="/chatOrders" element={<ChatOrders />}></Route>
          <Route path="/callOrders" element={<CallOrders />}></Route>
          <Route path="/contactInquiries" element={<ContactInquiries/>}></Route>
          <Route path="/contactDetails/:id" element={<ContactDetails/>}></Route>
          <Route path="/pujaRecommendation" element={<PujaRecommendationList/>}></Route>
          <Route path="/customers" element={<Customers/>}></Route>
          <Route path="/respondercallhistory" element={<Respondercallhistrory/>}></Route>
          <Route path="/addQuestion" element={<AddQuestion/>}></Route>
          <Route path="/chadhawaList" element={<ChadhawaList/>}></Route>
          <Route path="/manokamanaList" element={<ManokamnaList/>}></Route>
          <Route path="/astrologerList" element={<AstrologerList/>}></Route>
          <Route path="/reportsInquiry" element={<ReportsInquiry/>}></Route>

        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
