import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import DynamicDataTableWithCount from "../../components/dataTableWithCount";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import APIServicenew from "../../utils/APIGeneralService";
import Cookies from "universal-cookie";
import { formateDate, setCookies } from "../../utils/Helper";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Papa from "papaparse";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from 'react-select';

function ContactInquiries() {
  document.title = "Contact Inquiries";
  const cookies = new Cookies();
  let token = cookies.get("accessTokenleads");
  let astroUserId = cookies.get("astroUserId");
  const { refreshToken,countryList } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("");
  const [inquireStatus, setInquireStatus] = useState("");
  const [flag, setFlag] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const APIServices = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  const APIServiceschatbot = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  // const [countryOption, setCountryOption] = useState([
  //   { name: "India", id: "65effd4325ca275efc0d2f67" },
  //   { name: "USA", id: "65effee925ca275efc0d3651" },
  // ]);

  const [countData,setCountData]=useState()
  
  // const formateDate = date => {
  //   return moment(date).format('MM[-]DD[-]YYYY');
  // };

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };

  const statusMap = {
    "in-progress": { text: "In-progress", testClass: "text-warning fw-bold" },
    pending: { text: "Pending", testClass: "text-pending fw-bold" },
    closed: { text: "Closed", testClass: "text-danger fw-bold" },
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate("");
      setEndDate("");
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };


  useEffect(() => {
    fetchContactList(skip, limit, pageIndex);
    handleCountData()
  }, [flag]);


  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Name",
      Cell: (e) => {
        return <span>{e.row.original.name ?? "_"}</span>;
      },
    },
    {
      Header: "Country",
      Cell: (e) => {
        return <span>{e.row.original.countryId?.name ?? "_"}</span>;
      },
    },
    {
      Header: "Email",
      Cell: (e) => {
        return <span className="text-lowercase">{e.row.original.email ?? "_"}</span>;
      },
    },
    {
      Header: "Mobile Phone",
      Cell: (e) => {
        return <span>{e.row.original.contactNumber ?? "_"}</span>;
      },
    },
    {
      Header: "Inquiry Status",
      Cell: (e) => {
        return (
          <span className={statusMap[e.row.original.inquireStatus]?.testClass}>
            {statusMap[e.row.original.inquireStatus]?.text}
          </span>
        );
      },
    },
    {
      Header: "Inquiry Date",
      Cell: (e) => {
        return <span>{moment(e.row.original.createdAt).format("D MMM, YYYY h:mm A")}</span>;
      },
    },
    {
      Header: "Updated Date",
      Cell: (e) => {
        return <span>{moment(e.row.original.updatedAt).format("D MMM, YYYY h:mm A")}</span>;
      },
    },
    {
      Header: "Action",
      Cell: (e) => {
        if (e.row.original._id) {
          return (
            <h5>
              <input
                type="button"
                className="Et-btn"
                value="View"
                onClick={() =>
                  navigate(`/contactDetails/${e.row.original._id}`)
                }
              />
            </h5>
          );
        }
      },
    },
  ]);

  useEffect(()=>{
    authorizeResponder()
  },[])
  
  const authorizeResponder =async()=>{
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
        {
        },
        headers
      );
      if (res.data?.status == 4) {
        logout();
      }
    }


    const handleCountData = async (id) => {
      const res = await APIServices.get(`lead/getContactUsCount`, headers);
  
      if (res && res.status === 200) {
        if (res.data && res.data?.status) {
          setCountData(res.data.resData);
        } else {
          console.log("error");
        }
      }
    };

  const fetchContactList = useCallback(
    async (skip, limit, index) => {
      try {
        // let page = skip/limit+1
        let countryId = country?.value === undefined ? '' : country?.value;
        const response = await APIServices.get(
          `lead/contactUsList?skip=${skip}&limit=${limit}&name=${name}&email=${email}&contactNumber=${number}&startDate=${startDate ? formateDate(startDate) : "" }&endDate=${endDate ? formateDate(endDate) : "" }&countryId=${countryId}&inquireStatus=${inquireStatus=="all"?"":inquireStatus}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count);
          setTableData(response.data?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      name,
      email,
      number,
      startDate,
      endDate,
      country,
      inquireStatus,
    ]
  );

  const handleFilterClick = () => {
    if (
      name ||
      email ||
      number ||
      startDate ||
      endDate ||
      inquireStatus ||
      skip ||
      country
    ) {
      fetchContactList(
        skip,
        limit,
        0,
        name,
        email,
        number,
        startDate,
        endDate,
        country,
        inquireStatus
      );
    }
  };
  const handleInputChange = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setStartDate(formattedDate);
    // setStartDate(prevState => ({ ...prevState, startdate: formattedDate }));
  };
  const handleInputChangeEnd = (date) => {
    // const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setEndDate(formattedDate);
  };

  const resetData = () => {
    setNumber("");
    setEmail("");
    setName("");
    setStartDate("");
    setEndDate("");
    setSelectedRange("")
    setCountry("");
    setInquireStatus("");
    setFlag(true);
  };

 



  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0)
 };

const options = countryList.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
       value: item._id,
       label: item.name,
}));

  const fetchedAllBookingDownload = async () => {
    let countryId = country?.value === undefined ? '' : country?.value;
    const response = await APIServices.get(
      `lead/contactUsList?name=${name}&email=${email}&contactNumber=${number}&startDate=${startDate}&endDate=${endDate}&countryId=${countryId}&inquireStatus=${inquireStatus}`,
      headers
    );
    if (response?.data?.status === true) {
      handleDownload(response.data?.data);
    } else {
      if (response?.response?.data?.status == 4) {
        logout();
      }
    }
  };
  const handleDownload = (tableData) => {
    for (let item of tableData) {
      if (item.name) item["Name"] = item.name;
      // if (item.contactNumber) item['Mobile Phone'] = item.contactNumber;
      if (item.email) item["Email"] = item.email;
      if (item.countryId) item["Country"] = item.countryId?.name;
      item["Created Date "] = moment(item?.createdAt).format("D MMM, YYYY h:mm a");
      item["Updated Date "] = moment(item?.updatedAt).format("D MMM, YYYY h:mm a");
      // Add additional fields as needed

      // Delete unwanted fields
      delete item.name;
      delete item.contactNumber;
      delete item.email;
      delete item.countryId;
      delete item.number;
      delete item._id;
      delete item.contactBy;
      delete item.countryCode;
      delete item.reply;
      delete item.source;
      delete item.status;
      delete item.isDeleted;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.__v;
      // Delete additional unwanted fields
    }

    let csv = Papa.unparse(tableData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvData);
    } else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.setAttribute("download", "contact_data.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
        <div className="overflow-x-auto">  
            <div className="d-flex counter-wrapper align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                {/* {" "} */}
                Contact Inquiries <small>{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Inquiries <small>{countData?.usersAddedProgressToday}/{countData?.usersAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Inquiries <small>{countData?.usersAddedProgressThisWeek}/{countData?.usersAddedThisWeek}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Inquiries <small>{countData?.usersAddedProgressThisMonth}/{countData?.usersAddedThisMonth}</small>
              </h4>
              {/* <div className="_btn"></div> */}
            </div>
          </div>

        </div>
      </div>
      <ToastContainer />

      {/* <Container className="mb-2 "> */}
        <div className="chat_window">
          <Row className="mlist my-3 br-6 p-20 box-shadow">
            <div className="chat_search_box user-search">
              <div className="chat-search-field">
                  <Form>
                      <Form.Group as={Row} className="" controlId="">
                        <Col md={6} lg={3}>
                          <Form.Label className="label-f-w-cd">
                            Name
                          </Form.Label>

                          <Form.Control
                            className="mt-2 form-ch mb-3"
                            name="name"
                            type="search"
                            placeholder="Search By Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col md={6} lg={3}>
                          <Form.Label className="label-f-w-cd">
                            Email
                          </Form.Label>

                          <Form.Control
                            className="mt-2 form-ch mb-3"
                            name="email"
                            type="search"
                            placeholder="Search By Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col md={6} lg={3}>
                          <Form.Label className="label-f-w-c">
                            Mobile Number
                          </Form.Label>

                          <Form.Control
                            className="mt-2 form-ch mb-3"
                            name="number"
                            type="text"
                            placeholder="Search By Mobile Number"
                            value={number}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                        
                              // Regular expression to allow only numbers (no decimal point, no letters)
                              if (/^\d*$/.test(inputValue)) {
                                setNumber(inputValue); // Only update the state if the input is a valid number
                                setSkip(0);
                              }
                            }}
                            maxLength={10}
                          />
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                                <Form.Label className='label-f-w-cd'>
                                  Country
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={country}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Country...'
                                    isClearable
                                  />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="align-items-end" controlId="">
                        
                        <Col md={6} lg={3}>
                        <Form.Label className="label-f-w-cd">
                          Search By Inquiry Date
                        </Form.Label>
                        <div className="date-range-wrapper mt-2 mb-3">
                           <div className='select-icon-wrapper'> 
                              <select
                              className="select-range"
                                value={selectedRange}
                                onChange={(e) => handleDateRange(e.target.value)}
                              >
                                  <option value="">Select Date Range</option>
                                  <option value="today">Today</option>
                                  <option value="yesterday">Yesterday</option>
                                  <option value="thisWeek">This Week</option>
                                  <option value="last7Days">Last 7 Days</option>
                                  <option value="thisMonth">This Month</option>
                                  <option value="lastMonth">Last Month</option>
                                  <option value="customDate">Custom Date</option>
                              </select>
                              <img src='/images/down-arrow.png' className='select-icons'/>
                            </div>


                              {selectedRange === "customDate" && (
                                <DatePicker
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                  onChange={(update) => {
                                    const [start, end] = update;
                                    setStartDate(start);
                                    setEndDate(end);
                                  }}
                                  // isClearable={true}
                                  placeholderText="Select custom date range"
                                  dateFormat="yyyy-MM-dd"
                                />
                              )}
                          </div>
  
                        </Col>

                        {/* <Col md={2} className="mt-2">
                        
                          <DatePicker
                            placeholderText={"Select a start date"}
                            className="form-control form-ch fil-date-pick "
                            name="startdate"
                            selected={startDate ? new Date(startDate) : null}
                            onChange={(date) => {
                              handleInputChange(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col>

                        <Col md={2} className="mt-2">
                          
                          <DatePicker
                            placeholderText={"Select a end date"}
                            className="form-control form-ch fil-date-pick "
                            name="startdate"
                            selected={endDate ? new Date(endDate) : null}
                            onChange={(date) => {
                              handleInputChangeEnd(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col> */}

                        {/* <Form.Label className="label-f-w-cd  mt-2 "> Inquiry status</Form.Label> */}

                        <Col md={6} lg={3}>
                        <Form.Label className="label-f-w-cd">
                          Search By Status
                        </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 mb-3 form-ch"
                            value={inquireStatus}
                            onChange={(e) => {
                              setInquireStatus(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option value="all"> Inquiry status</option>
                            <option value="pending">Pending</option>
                            <option value="in-progress">In-progress</option>
                            <option value="closed">Closed</option>
                          </Form.Select>
                        </Col>
                        <Col className="mb-3" md={6} lg={3}>
                        <Button
                          variant="secondary"
                          className="as_btn ms-0"
                          onClick={() => {
                            handleFilterClick();
                            setSkip(0);
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="secondary"
                          className="as_btn reset-btn"
                          onClick={() => {
                            resetData();
                          }}
                        >
                          Reset
                        </Button>
                        </Col>
                        {/* <Button
          className='as_btn ms-2'
          variant='primary'
          disabled={!tableData || (tableData && tableData.length <= 0)}
          onClick={() => {
            fetchedAllBookingDownload();
          }}
        >
          Download File
        </Button> */}
                      </Form.Group>
                   
                  </Form>
             
              </div>
            </div>
          </Row>
          {tableData && tableData.length > 0 ? (
                <DynamicDataTableWithCount
                  columns={columns}
                  data={tableData}
                  totalCount={pageCount}
                  initialState={{
                    pageIndex: pageIndex,
                    pageSize: limit,
                  }}
                  fetchData={fetchContactList}
                  forcePage={skip / limit}
                />
                ) : (
                  <h2 className="no_records">Records not Available</h2>
                )}
        </div>
      {/* </Container> */}
      {/* </div> */}
      
    </div>
  );
}

export default ContactInquiries;
