import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import moment from "moment";
import APIServicenew from "../utils/APIGeneralService";
import {  getCookies, setCookies } from "../utils/Helper";
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../context/UserContextProvider";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import Select from 'react-select';


const ChadhawaList = () => {
  document.title = "Chadhawa List";
  const navigate = useNavigate();
  const token = getCookies("accessTokenleads");
  const headers = { Authorization: `Bearer ${token}` };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(40);
  const [pageIndex, setPageIndex] = useState(0);

  // filter name
  const [chadawaId, setChadawaId] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobNum, setUserMobNum] = useState("");
  const [templeName, setTempleName] = useState("");
  const [chadawaName, setChadawaName] = useState("");
  const [astroMobNum, setAstroMobNum] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [ispuja,setIspuja]= useState("")
  const [paymentStatus, setPaymentStatus] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const { refreshToken, countryList } = useContext(UserContext);
  const APIServicesNew = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  const { addToast } = useToasts();
  const { user: adminUser } = useContext(UserContext);
  const [countData,setCountData]=useState()
  // for notes
  const [userForNote, setUserForNote] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState("");
  const [error, setError] = useState(false);

  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };

  const handleUserNotes = async (id) => {
    const res = await APIServicesSec.get(
      `userNotes/getDetailById/${id}`,
      headers
    );

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();
        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.user_id,
        source:"Leads-Chadhawa-List",
        name:userForNote?.user_mobile,
        id:userForNote?.id,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServicesSec.post(
        `userNotes/userNoteMessage`,
        payload,
        headers
      );

      if (res && res?.data?.status === true) {
        addToast("Note added successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        // toast.error(res?.data?.message);
        addToast(res?.data?.message, {
          appearance: "denger",
          autoDismiss: true,
        });

        setLoader(false);
      }
      setLoader(false);
    }
  };



    // for call user
    const [showCallCm ,setShowCallCm] = useState(false)
    const [dial, setDail] = useState("Call");
    const [currentUserId ,setCurrentUserId] = useState('')
    const handleCloseCallCm = () => {
      setShowCallCm(false);
    };

    const APIServiceschatbot = new APIServicenew(
      process.env.REACT_APP_OTHER_API_URL
    );

    const userCall = async () => {
      const res = await APIServiceschatbot.post(
        "responders/call",
        {
          userId: currentUserId,
          source: "chadhawa",
        },
        headers
      );
  
      if (res && res.status === 200) {
        setDail(res?.data?.message);
        setShowCallCm(false);
        addToast("Calling....", {
          appearance: "success",
          autoDismiss: true,
        });
        setTimeout(() => {
          setDail("Call");
        }, 10000);
      }
    };
  
    const handleConfirm = () => {
      userCall();
    };

  // date filter

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const resetData = () => {
  setChadawaId('')
  setUserName('')
  setUserMobNum('')
  setTempleName('')
  setChadawaName('')
  setAstroMobNum('')
  setCurrencyCode('')
  setTransactionId('')
  setSelectedRange('')
  setSource('')
  setStatus('')
  setRefundStatus('')
  setStartDate("")
  setEndDate("")
  setPaymentStatus('')
  setFlag(true);
  setPageIndex(0);
  setCountry('')
  };

  useEffect(() => {
    getAllData(skip, limit, pageIndex);
    handleCountData()
  }, [flag]);

  const handleSearch = () => {
    if (
        chadawaId || userName || userMobNum || templeName || chadawaName ||  currencyCode ||
         transactionId || source || status || refundStatus || paymentStatus ||ispuja ||
        startDate || 
        endDate || country
    ) {
      getAllData(
        skip,
        limit,
        0,
        chadawaId , userName , userMobNum , templeName , chadawaName ,  currencyCode ,
         transactionId , source , status , refundStatus , paymentStatus ,ispuja,
            startDate ,
            endDate, country
      );
    }
  };

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Temple Name	",
      Cell: (e) => {
        return (
          <>
            <span>{e.row.original.temple_name}</span>
            <br />
          </>
        );
      },
    },
    {
      Header: "Chadhawa Id",
      Cell: (e) => {
        return <span>{e.row.original.id ?? "_"}</span>;
      },
    },
    {
      Header: "User Name",
      Cell: (e) => {
        return (
          <>
            <span>{e.row.original.user_name ?? "_"}</span>
            <br />
          </>
        );
      },
    },
    {
      Header: "User Mobile Number",
      Cell: (e) => {
        return (<>
                  <span> {e.row.original.user_mobile ?? "_"}</span>
                  <br/>
                  <button
                      onClick={() => {
                        setShowCallCm(true);
                        setCurrentUserId(e.row.original.user_id);
                      }}
                      className="mt-2  call_btn btn btn-secondary"
                    >
                     Call to User
                  </button>
               </>
                
        ) 
      },
    },
    {
      Header: "Chadhawa Name",
      Cell: (e) => {
        return <span>{e.row.original.chadawa_name ?? "_"}</span>;
      },
    },
    {
      Header: "Country Name",
      Cell: (e) => {
        return <span>{e.row.original.country ?? "_"}</span>;
      },
    },

    {
      Header: "Amount",
      Cell: (e) => {
        return <span>{e.row.original.currency_symbol}{e.row.original.amount}</span>;
      },
    },
    {
      Header: "Transaction Id",
      Cell: (e) => {
        return (
          <span>{e.row.original.transaction_id ?? "_"}</span>
        );
      },
    },
    {
      Header: 'Language',
      Cell: e => {
        return <span>{e.row.original?.language ?? '_'}</span>;
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return<span>{e.row.original?.is_puja === 1 ? e.row.original.source + "(Puja)" : e.row.original.source ?? "_"}</span>
        //  <span>{e.row.original.source ?? "_"}</span>;
      },
    },
    {
      Header: "Payment Status",
      Cell: (e) => {
        return <span
         className={e.row.original.payment_status === "paid" ? "text-success fw-bold font_14" : "text-pending fw-bold font_14"}
          >
            {e.row.original.payment_status ?? "_"}
          </span>;
      },
    },
    // {
    //     Header: "Booking Status(refund)",
    //     Cell: (e) => {
    //       return <span>{e.row.original.status ?? "_"}</span>;
    //     },
    //   },
    {
      Header: "Booking Status(refund)",
      Cell: (e) => {
        return (
          <>
            {e.row.original?.refund_status === "pending" && e.row.original.status === "cancelled" ? (
              <div className="d-inline-flex flex-column gy-2">
                <span className="text-danger fw-bold font_14 mb-1">{e.row.original.status ?? "_"}</span>
                <span className="text-pending fw-bold font_14">pending</span>
              </div>
            ) : e.row.original?.refund_status === "completed" && e.row.original.status === "cancelled" ? (
              <div className="d-inline-flex flex-column gy-2">
                <span className="text-danger fw-bold font_14 mb-1">{e.row.original.status ?? "_"}</span>
                <span className="text-success fw-bold font_14">Completed</span>
              </div>
            ) : (
              <span
              className={e.row.original.status === "completed" ? "text-success fw-bold font_14" : e.row.original.status === "processing" ? "text-warning fw-bold font_14" : "text-danger fw-bold font_14"}
              >
                {e.row.original.status ?? "_"}</span>
            )}
          </>
        );
      },
    },

    {
      Header: "Created Date	",
      Cell: (e) => {
        return (
          <span>
            {moment.utc(e.row.original.created_at).local().format("D MMM, YYYY h:mm A")}
          </span>
        );
      },
    },
    {
      Header: 'Notes',
      Cell: e => {
        return (
          <h5>
            <input
              type='button'
              className='Et-btn notes-btn'
              value='Notes'
              onClick={() => {
                setUserForNote(e.row.original);
                handleUserNotes(e.row.original.user_id);
              }}
            />
             
          </h5>
        );
      },
    },
  ]);

  useEffect(() => {
    authorizeResponder();
  }, []);

  const [country, setCountry] = useState('');
  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0)
 };

const options = countryList.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
       value: item.name,
       label: item.name,
}));


  const authorizeResponder = async () => {
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
      {},
      headers
    );
    if (res.data?.status == 4) {
      logout();
    }
  };

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };




  const handleCountData = async () => {
    const res = await APIServicesSec.get(`lead/getChadawacount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.data);
      } else {
        console.log("error");
      }
    }
  };

  const getAllData = useCallback(
    async (skip, limit, index) => {
      try {
        let countryId = country?.value === undefined ? '' : country?.value;
        let created_at = '';
        if(startDate && endDate){
            let sDate = moment(startDate).format('MM/DD/YYYY');
            let eDate = moment(endDate).format('MM/DD/YYYY');
            created_at = sDate+" - "+eDate;
        }  
        let page  = skip/limit+1;
        const response = await APIServicesSec.get(
          `lead/mandirChadawaBookingList/${page}/${limit}?userName=${userName}&country=${countryId}&source=${source=="all"?"":source}&templeName=${templeName}&currencyCode=${currencyCode=="all"?"":currencyCode}&transactionId=${transactionId}&createdAt=${created_at}&userMobile=${userMobNum}&refundStatus=${refundStatus}&paymentStatus=${paymentStatus=="all"?"":paymentStatus}&status=${status=="all"?"":status}&chadawaId=${chadawaId}&chadawaName=${chadawaName}&isPuja=${ispuja=="all"?"":ispuja}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      // setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      chadawaId , userName , userMobNum , templeName , chadawaName ,  currencyCode , 
      transactionId , source , status , refundStatus ,ispuja, paymentStatus,startDate ,
      endDate,country
    ]
  );
  const [allBookingDownload,setAllBookingDownload] = useState([])

  const handleDownload = (data) => {
    
    for (let puja of data) {
      if (puja.temple_name) {
        puja['Temple Name '] = puja?.temple_name;
      }
      if (puja.id) {
        puja['Chadhawa Id'] = puja.id;
      }
      if (puja.user_name) {
        puja['User Name'] = puja.user_name;
      }
      if (puja.user_mobile) {
        puja['User Number'] = puja.user_mobile;
      }
      if (puja.chadawa_name) {
        puja['Chadhawa Name'] = puja?.chadawa_name ?? '';
       }
      if (puja.amount) {
        puja['Amount'] = puja.amount === "0" ? "Free" : puja.currency_symbol+puja.amount;
      }
      if (puja.transaction_id) {
        puja['Transaction Id'] = puja?.transaction_id ?? '';
      }
      if (puja.status) {
        puja['Booking Status'] = puja.status;
      }
      
      if (puja.payment_status) {
        puja['Payment Status'] = puja.payment_status;
      }
      if (puja.refund_status) {
        puja['Booking Status(refund)'] = puja.refund_status ?? '';
      }

      if (puja.created_at) {
        puja['Created Date '] = moment.utc(puja?.created_at).local().format("D MMM, YYYY h:mm a")
      }
    
      
      delete puja.currency_symbol;
      delete puja.temple_name;
      delete puja.id;
      delete puja.user_name;
      delete puja.user_mobile;
      delete puja?.chadawa_name;
      delete puja.amount;
      delete puja.transaction_id;
      delete puja.source;
      delete puja.payment_status;
      delete puja.refund_status;
      delete puja.created_at;
      delete puja.status;
   
    }

    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveBlob(csvData, "UserList.csv");
    else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.download = "Pujabooking.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };


const fetchedAllBookingDownload = async () => {
  let created_at = '';
        if(startDate && endDate){
            let sDate = moment(startDate).format('MM/DD/YYYY');
            let eDate = moment(endDate).format('MM/DD/YYYY');
            created_at = sDate+" - "+eDate;
        }

        const res = await APIServicesSec.get(
          `lead/mandirChadawaBookingList?userName=${userName}&source=${source}&templeName=${templeName}&currencyCode=${currencyCode}&transactionId=${transactionId}&createdAt=${created_at}&userMobile=${userMobNum}&refundStatus=${refundStatus}&paymentStatus=${paymentStatus}&status=${status}&chadawaId=${chadawaId}&chadawaName=${chadawaName}`,
          headers
        );
  if (res && res.status === 200) {
    let resData = res.data;
    if (resData && resData.status) {
      setAllBookingDownload(resData?.Listdata);
      handleDownload(resData?.Listdata);
    }
  }
};

  return (
    <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">    
            <div className="d-flex counter-wrapper align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                Total Bookings <small>{countData?.totalPaidChadawa}/{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Chadhawa <small>{countData?.todaysPaidChadawa}/{countData?.todaysChadawa}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Chadhawa <small>{countData?.thisWeekPaidChadawa}/{countData?.thisWeekChadawa}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Chadhawa <small>{countData?.thisMonthPaidChadawa}/{countData?.thisMonthChadawa}</small>
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* <Container className="mb-2 "> */}
      <div className="chat_window">
        <Row className="mlist  my-3 br-6 p-20">
          <div className="chat_search_box user-search">
            <div className="chat-search-field">
              <Row>
                <Form>
                  <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">Chadhawa Id</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="call number"
                        type="text"
                        placeholder="Chadhawa Id"
                        value={chadawaId}
                        onChange={(e) => {
                          setChadawaId(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">User Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Name"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">
                        User Mobile Number
                      </Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="number"
                        type="text"
                        placeholder="Search Mobile Number"
                        value={userMobNum}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                    
                          // Regular expression to allow only numbers (no decimal point, no letters)
                          if (/^\d*$/.test(inputValue)) {
                            setUserMobNum(inputValue); // Only update the state if the input is a valid number
                            setSkip(0);
                          }
                        }}
                        maxLength={10}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">Temple Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Name"
                        value={templeName}
                        onChange={(e) => {
                          setTempleName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">Chadhawa Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Chadhawa Name"
                        value={chadawaName}
                        onChange={(e) => {
                          setChadawaName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">
                        Currency Code
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={currencyCode}
                        onChange={(e) => {
                          setCurrencyCode(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value="all"> Select</option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">
                        Transaction Id
                      </Form.Label>

                      <Form.Control
                        className="mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Transaction Id"
                        value={transactionId}
                        onChange={(e) => {
                          setTransactionId(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-0 mb-lg-0">
                      <Form.Label className="label-f-w-cd">
                     Booked From Puja
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={ispuja}
                        onChange={(e) => {
                          setIspuja(e.target.value);
                          setSkip(0);
                        }}
                      >
                        {/* <option defaultValue="">Select Booked From Puja</option> */}
                        <option value ="all">Select Booked From Puja</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </Col>

                    {/* <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label className="label-f-w-cd">
                      Refund Status
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={refundStatus}
                        onChange={(e) => {
                            setRefundStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option defaultValue="">Select Refund Status</option>
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                      </Form.Select>
                    </Col> */}

                  </Form.Group>
                 

                  <Form.Group
                    as={Row}
                    className="align-items-end mb-1"
                    controlId=""
                  >
                   

                    <Col lg={3} md={6} className="mb-3 mb-lg-3">
                      <Form.Label className="label-f-w-cd">Source</Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={source}
                        onChange={(e) => {
                          setSource(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value="all"> All</option>
                        <option value="app">App</option>
                        <option value="web">Web</option>
                        <option value="global">Global</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-3">
                      <Form.Label className="label-f-w-cd">
                        Payment Status
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={paymentStatus}
                        onChange={(e) => {
                            setPaymentStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value="all">Select Payment Status</option>
                        <option value="pending">Pending</option>
                        <option value="paid">Paid</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-3 ">
                      <Form.Label className="label-f-w-cd">
                        Select Booking Status
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value="all"> All</option>
                        <option value="processing">Processing</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </Form.Select>
                    </Col>
                    
                    <Col md={6} lg={3} xl={3} className="mb-3 mb-lg-3">
                      <Form.Label className="label-f-w-cd">
                        Select By Booking Date
                      </Form.Label>
                      <div className="date-range-wrapper mt-2">
                        <div className='select-icon-wrapper'>
                          <select
                            className="select-range"
                            value={selectedRange}
                            onChange={(e) => handleDateRange(e.target.value)}
                          >
                            <option value="">Select Date Range</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="thisWeek">This Week</option>
                            <option value="last7Days">Last 7 Days</option>
                            <option value="thisMonth">This Month</option>
                            <option value="lastMonth">Last Month</option>
                            <option value="customDate">Custom Date</option>
                          </select>
                          <img src='/images/down-arrow.png' className='select-icons'/>
                        </div>

                        {selectedRange === "customDate" && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            // isClearable={true}
                            placeholderText="Select custom date range"
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                      </div>
                    </Col>

                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="align-items-end mb-1"
                      controlId=""
                  >
                      <Col lg={3} md={6} className=''>
                                <Form.Label className='label-f-w-cd'>
                                  Country
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={country}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Country...'
                                    isClearable
                                  />
                            </Col>
                      <Col lg={6} md={6} className="mb-3 mb-lg-0">
                        <Button
                          variant="secondary"
                          className="as_btn ms-0"
                          onClick={() => {
                            handleSearch();
                            setSkip(0);
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="secondary"
                          className="as_btn reset-btn"
                          onClick={() => {
                            resetData();
                          }}
                        >
                          Reset
                        </Button>
                        {/* <Button
                          className="as_btn download-btn"
                          variant="primary"
                          onClick={() => {
                            fetchedAllBookingDownload();
                          }}
                        >
                          Download Booking
                        </Button> */}
                      </Col>
                  </Form.Group>
                  
                </Form>
              </Row>
            </div>
          </div>
        </Row>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            // notShowPage ={true}
            fetchData={getAllData}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className="no_records">Records not Available</h2>
        )}
      </div>

       {/* user call confirmation */}
       <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
        centered
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for user note */}
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3">
              {" "}
              Name :- {userForNote?.user_name
                ? userForNote?.user_name
                : ""}{" "}
            </span>
            <span>
              {" "}
              Contact Number :-{" "}
              {userForNote?.user_mobile
                ? userForNote?.user_mobile
                : ""}{" "}
            </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn reset-btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
          <Row>
          
            </Row> 
          
          
          {notesData && notesData?.notes?.length > 0 ? (
            <>
              { notesData?.notes && notesData?.notes?.length>0 &&notesData?.notes?.map((item, index) => (
                <>
                <Row>
                          {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }
{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ):
    item.source === 'Marketing-Reports-Inquiry'
    || item.source === "Leads-Reports-Inquiry" ?
    <Col xs={7}>
          <span className='aa'>Reports Name :- </span>
          <span className='item-msg'>
            
              {item?.sourcename?.length <= 50
                ? item?.sourcename
                : `${item?.sourcename?.substring(0, 40)}...`}
          </span>
        </Col> : null
)}
                </Row>
                
                  <Row className="notes-item">
                    <Col xs={9}>
                      <span className="item-name aa">
                              Note :- 
                      </span>
                      <span className="item-msg"> {item?.message}</span>
                    </Col>
                   
                   
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                          <span className="text-primary item-name aa">
                            {item?.responderName || item?.adminName}
                          </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("D MMM, YYYY h:mm a")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChadhawaList;
