import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import {
  Card,
  Container,
  Form,
  Button,
  Row,
  Col,
  Tab,
  Nav,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import { UserContext } from "../context/UserContextProvider";
import moment from "moment";
import APIServicenew from "../utils/APIGeneralService";
import Cookies from "universal-cookie";
import { setCookies, formateDate } from "../utils/Helper";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";

const ReportsInquiry = () => {
  document.title = "Report List";
  const cookies = new Cookies();
  let token = cookies.get("accessTokenleads");
  const { refreshToken,countryList } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );
  const headers = { Authorization: `Bearer ${token}` };
  const { addToast } = useToasts();
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [source, setSource] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [sort, setSort] = useState("");

  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");

  useEffect(() => {
    titleDropdown();
    authorizeResponder()
  }, []);

  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };

  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "reports",
      },
      headers
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    userCall();
  };

  // for notes
  const [userForNote, setUserForNote] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState("");
  const [error, setError] = useState(false);

  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };

  const handleUserNotes = async (id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();
        setNotesData(res?.data?.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId,
        source: "Leads-Reports-Inquiry",
        name: userForNote?.reportId?.title,
        id: userForNote?._id,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServices.post(
        `userNotes/userNoteMessage`,
        payload,
        headers
      );

      if (res && res?.data?.status === true) {
        addToast("Note added successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        // toast.error(res?.data?.message);
        addToast(res?.data?.message, {
          appearance: "denger",
          autoDismiss: true,
        });

        setLoader(false);
      }
      setLoader(false);
    }
  };

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };

  const authorizeResponder = async () => {
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
      {},
      headers
    );
    if (res.data?.status == 4) {
      logout();
    }
  };

  const handleReset = () => {
    setTitle("");
    setEmail("");
    setMobileNumber("");
    setSource("");
    setPaymentStatus("");
    setSort("");
    setStartDate("");
    setEndDate("");
    setSelectedRange("");
    setSelectedOption("");
    setFlag(true);
    setPageIndex(0);
    setCountry("");
  };

  const [viewDetails, setViewDetails] = useState([]);
  const [showSingleDataDetail, setShowSingleDataDetail] = useState(false);

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Title",
      Cell: (e) => {
        return <span>{e.row.original?.reportId?.title ?? "_"}</span>;
      },
    },
    {
      Header: "Name",
      Cell: (e) => {
        return <span>{e.row.original.name ?? "_"}</span>;
      },
    },
    {
      Header: "Email",
      Cell: (e) => {
        return (
          <span className="text-lowercase">{e.row.original.email ?? "_"}</span>
        );
      },
    },
    {
      Header: "Mobile Number",
      Cell: (e) => {
        return (
          <>
            <span className="text-lowercase">
              {e.row.original.contactNumber ? e.row.original.contactNumber : "_"}
            </span>
            <br />
            {e.row.original?.contactNumber &&
              <button
                onClick={() => {
                  setShowCallCm(true);
                  setCurrentUserId(e.row.original.userId);
                }}
                className="mt-2  call_btn btn btn-secondary"
              >
                Call to User
              </button>
            }
          </>
        );
      },
    },
    {
      Header: "Payment Status",
      Cell: (e) => {
        // return <span>{e.row.original.paymentStatus ?? '_'}</span>;
        return (
          <span
            className={
              e.row.original.paymentStatus === "Paid"
                ? "text-success fw-bold font_14"
                : e.row.original.paymentStatus === "Pending"
                ? "text-pending fw-bold font_14"
                : "text-danger fw-bold font_14"
            }
          >
            {e.row.original.paymentStatus ?? "_"}
          </span>
        );
      },
    },
    {
      Header: "Country Name",
      Cell: (e) => {
        return <span>{e.row.original?.country ? e.row.original?.country : "_"}</span>;
      },
    },
    {
      Header: "Language",
      Cell: (e) => {
        return <span>{e.row.original?.language ?? "_"}</span>;
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return <span>{e.row.original.utmSource ?? "_"}</span>;
      },
    },
    // {
    //   Header: "Amount",
    //   Cell: (e) => {
    //     return (
    //       <span>
    //         {e.row.original.currencySymbol}
    //         {e.row.original.amount ?? "_"}
    //       </span>
    //     );
    //   },
    // },
    {
      Header: "Created At",
      Cell: (e) => {
        return (
          <>
            <span>
              {e.row.original.createdAt
                ? moment(e.row.original.createdAt).format(
                    "DD MMM, YYYY hh:mm A"
                  )
                : "_"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Details",
      Cell: (e) => {
        return (
          <Button
            className="detail_btn"
            type="button"
            onClick={() => {
              setViewDetails(e.row.original);
              setShowSingleDataDetail(true);
            }}
          >
            View Details
          </Button>
        );
      },
    },
    {
      Header: "Notes",
      Cell: (e) =>
        e.row.original?.userId ? (
          <h5>
            <input
              type="button"
              className="Et-btn notes-btn"
              value="Notes"
              onClick={() => {
                setUserForNote(e.row.original);
                handleUserNotes(e.row.original?.userId);
              }}
            />
          </h5>
        ) : null,
    },
  ]);
 
  const [country, setCountry] = useState("");
  // const handleCountryChange = e => {
  //   setCountry(e.target.value);
  //   setSkip(0);
  // };
  const handleSelectChangeCountry = (selected) => {
    setCountry(selected);
    setSkip(0);
  };

  const countryOptions = countryList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({
      value: item._id,
      label: item.name,
    }));

  const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const titleDropdown = async () => {
    const response = await APIServices.get(
      `reportservices/reportlist`,
      headers
    );
    if (response?.status === 200) {
      setDropdownData(response?.data?.Listdata);
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    setSkip(0);
  };

  const options = dropdownData.map((item) => ({
    value: item._id,
    label: item.title,
  }));

  //Date

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  useEffect(() => {
    fetchReportServiceInquiries(skip, limit, pageIndex);
  }, [flag]);

  const fetchReportServiceInquiries = useCallback(
    async (skip, limit, index) => {
      try {
        let countryId = country?.value === undefined ? "" : country?.value;
        const response = await APIServices.get(
          `lead/getAllReportServiceInquiries/${skip}/${limit}?name=${title}&countryId=${countryId}&email=${email}&contactNumber=${mobileNumber}&reportId=${
            selectedOption?.value ? selectedOption?.value : ""
          }&paymentStatus=${paymentStatus}&utmSource=${source}&startDate=${
            startDate ? formateDate(startDate) : ""
          }&endDate=${endDate ? formateDate(endDate) : ""}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      title,
      source,
      sort,
      email,
      mobileNumber,
      paymentStatus,
      startDate,
      endDate,
      selectedOption?.value,
      country,
    ]
  );

  const handleFilterClick = () => {
    if (
      title ||
      source ||
      paymentStatus ||
      sort ||
      email ||
      mobileNumber ||
      country ||
      startDate ||
      endDate ||
      selectedOption?.value
    ) {
      fetchReportServiceInquiries(
        skip,
        limit,
        0,
        title,
        source,
        sort,
        email,
        mobileNumber,
        paymentStatus,
        startDate,
        endDate,
        selectedOption?.value,
        country
      );
    }
  };

  return (
    <div>
      <div className="App">
        <div className="gita_quote_heading">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="hd-list-f left-q ">
                {" "}
                Report Inquiry List <small>({allDataCount})</small>
              </h4>
              {/* <button className='as_btn' onClick={() => navigate('/reportsAddEdit')}>
                Add Report
              </button> */}
            </div>
          </div>
        </div>

        <div className="user-list-table">
          <div className="feed_area box-shadow my-3 p-20 br-6">
            <div className="chat_search_box p-0">
              <div className="chat-search-field">
                <Form>
                  <Form.Group
                    as={Row}
                    className="align-items-end"
                    controlId=""
                    // align
                  >
                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">Search by Name</Form.Label>
                      <Form.Control
                        className="form-ch"
                        name="title"
                        type="search"
                        placeholder="Search By Name"
                        value={title}
                        // onChange={e => setTitle(e.target.value)}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">Search by Email</Form.Label>
                      <Form.Control
                        className="form-ch"
                        name="email"
                        type="search"
                        placeholder="Search By Email"
                        value={email}
                        // onChange={e => setEmail(e.target.value.toLowerCase())}
                        onChange={(e) => {
                          setEmail(e.target.value.toLowerCase());
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">Search by Number</Form.Label>
                      <Form.Control
                        className="form-ch"
                        name="mobileNumber"
                        type="search"
                        placeholder="Search By Number"
                        value={mobileNumber}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">
                        Search by Payment Status
                      </Form.Label>
                      <Form.Select
                        aria-label="categories"
                        className="astroWatch-select-hover mt-0 hd-cr form-ch"
                        value={paymentStatus}
                        onChange={(e) => {
                          setPaymentStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option> Payment Status </option>
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                        <option value="Fail">Failed</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">Search by Source</Form.Label>
                      <Form.Select
                        aria-label="categories"
                        className="astroWatch-select-hover mt-0 hd-cr form-ch"
                        value={source}
                        onChange={(e) => {
                          setSource(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option>Source</option>
                        <option value="web">Web</option>
                        <option value="app">App</option>
                        <option value="global">Global</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="">Search by Title</Form.Label>
                      <Select
                        classNamePrefix="react-select"
                        options={options}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        placeholder="Search by Title..."
                        isClearable
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="label-f-w-cd">Country</Form.Label>
                      <Select
                        className=" mt-2"
                        classNamePrefix="react-select"
                        options={countryOptions}
                        value={country}
                        onChange={handleSelectChangeCountry}
                        placeholder="Search by Country..."
                        isClearable
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3">
                      <Form.Label className="label-f-w-cd">
                        Search By Booking Date
                      </Form.Label>
                      <div className="date-range-wrapper">
                        <div className="select-icon-wrapper">
                          <select
                            className="select-range"
                            style={{ border: "1px solid grey" }}
                            value={selectedRange}
                            onChange={(e) => {
                              handleDateRange(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option value="disabled">Select Date Range</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="thisWeek">This Week</option>
                            <option value="last7Days">Last 7 Days</option>
                            <option value="thisMonth">This Month</option>
                            <option value="lastMonth">Last Month</option>
                            <option value="customDate">Custom Date</option>
                          </select>
                          <img
                            src="/images/down-arrow.png"
                            className="select-icons"
                          />
                        </div>
                        {selectedRange === "customDate" && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            // isClearable={true}
                            placeholderText="Select custom date range"
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                      <Button
                        variant="secondary"
                        className="as_btn ms-1"
                        onClick={() => {
                          handleFilterClick();
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant="secondary"
                        className="as_btn reset-btn"
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Reset
                      </Button>
                      {/* <Button variant='secondary' className='as_btn' onClick={() => navigate('/templeAddEdit')}>
                            Add +
                          </Button> */}
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchReportServiceInquiries}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className="no_records">Records not Available</h2>
        )}

        {/* show Detail data */}

        <Modal
          centered
          className="astrologer_detail-modal puja-booking-modal"
          show={showSingleDataDetail}
          size="md"
          scrollable={true}
          aria-labelledby="example-modal-sizes-title-sm"
          backdrop="static"
          onHide={() => setShowSingleDataDetail(false)}
        >
          <Modal.Header className="modal-header-hd" closeButton>
            <Modal.Title> Reports Detail </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <div className="row align-items-start gy-1 mb-2">
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Name:</p>
                    <p className="my-1 w-70">{viewDetails?.name}</p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Email:</p>
                    <p className="my-1 w-70">{viewDetails?.email}</p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Mobile Number:</p>
                    <p className="my-1 w-70">{viewDetails?.contactNumber}</p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Gender:</p>
                    <p className="my-1 w-70">
                      {viewDetails?.additionalDetails?.gender}
                    </p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Date of Birth:</p>
                    <p className="my-1 w-70">
                      {moment
                        .utc(viewDetails?.additionalDetails?.dob)
                        .set({
                          hour: viewDetails?.additionalDetails?.hour,
                          minute: viewDetails?.additionalDetails?.min,
                        })
                        .format("DD MMM, YYYY hh:mm A")}
                    </p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Birth Place:</p>
                    <p className="my-1 w-70">
                      {viewDetails?.additionalDetails?.address}
                    </p>
                  </div>
                  {/* <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Amount:</p>
                    <p className="my-1 w-70">
                      {viewDetails?.currencySymbol}
                      {viewDetails?.amount}.00
                    </p>
                  </div> */}
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Question :</p>
                    <p className="my-1 w-70">{viewDetails?.description}</p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-between align-items-start gap-2">
                    <p className="my-1 w-30 fw-semibold">Payment Status:</p>
                    <p className="my-1 w-70">{viewDetails?.paymentStatus}</p>
                  </div>
                </div>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={() => setShowSingleDataDetail(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* for user note */}
        <Modal
          centered
          show={show}
          size="md"
          // aria-labelledby='example-modal-sizes-title-sm'
          scrollable={true}
          backdrop="static"
          onHide={handleCloseModal}
        >
          <Modal.Header className="modal-header-hd" closeButton>
            <Modal.Title> Add Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="aa d-flex">
              <span className="me-3">
                {" "}
                Name :- {userForNote?.name ? userForNote?.name : ""}{" "}
              </span>
              <span>
                {" "}
                Contact Number :-{" "}
                {userForNote?.contactNumber
                  ? userForNote?.contactNumber
                  : ""}{" "}
              </span>
            </div>

            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="my-3"
                  controlId="formAartiInput"
                >
                  <Col sm="12">
                    <Form.Control
                      as="textarea"
                      name="content"
                      placeholder="Type here..."
                      value={note}
                      style={{ height: 100 }}
                      onChange={(e) => {
                        handleChange();
                        setNote(e.target.value);
                      }}
                    />
                    {error ? (
                      <p className="text-danger">Please enter your note</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer className="py-2 px-0">
              {loader ? (
                <Button variant="primary" className="as_btn">
                  Processing...
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="as_btn"
                  onClick={handleNotesAdd}
                >
                  Add
                </Button>
              )}
              <Button
                variant="secondary"
                className="as_btn reset-btn"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </Modal.Footer>

            <h6 className="aa mt-1"> Notes History :-</h6>
            <Row></Row>

            {notesData && notesData?.notes?.length > 0 ? (
              <>
                {notesData?.notes &&
                  notesData?.notes?.length > 0 &&
                  notesData?.notes?.map((item, index) => (
                    <>
                      <Row>
                        {item?.source && item?.source != "" ? (
                          <Col xs={5}>
                            <span className="aa">Source :- </span>
                            <span className="item-msg">
                              {item?.source ? item?.source : ""}{" "}
                            </span>
                          </Col>
                        ) : (
                          ""
                        )}

                        {item?.source &&
                          item?.source !== "" &&
                          (item.source === "Leads-Chat-Orders" ||
                          item.source === "Leads-Call-Orders" ? (
                            item?.sourcename &&
                            item.sourcename !== "" && (
                              <Col xs={7}>
                                {item.source === "Leads-Chat-Orders" ? (
                                  <span className="aa">Chat Id :- </span>
                                ) : (
                                  <span className="aa">Call Id :- </span>
                                )}
                                <span className="item-msg">
                                  {item?.sourcename?.length <= 50
                                    ? item?.sourcename
                                    : `${item?.sourcename?.substring(
                                        0,
                                        40
                                      )}...`}
                                </span>
                              </Col>
                            )
                          ) : item.source === "Leads-Chadhawa-List" ||
                            item.source === "Leads-Manokamna-List" ||
                            item.source === "Marketing-Chadhawa-List" ||
                            item.source === "Marketing-Manokamna-List" ? (
                            item?.sourceid &&
                            item.sourceid !== "" && (
                              <Col xs={7}>
                                {item.source === "Leads-Chadhawa-List" ||
                                item.source === "Marketing-Chadhawa-List" ? (
                                  <span className="aa">Chadawa Id :- </span>
                                ) : (
                                  <span className="aa">Manokmana Id :- </span>
                                )}
                                <span className="item-msg">
                                  {item?.sourceid?.length <= 50
                                    ? item?.sourceid
                                    : `${item?.sourceid?.substring(0, 40)}...`}
                                </span>
                              </Col>
                            )
                          ) : item.source === "Leads-Puja-Booking" ||
                            item.source === "Leads-Puja-Suggestion" ||
                            item.source === "Marketing-Puja-Booking" ||
                            item.source === "Marketing-Puja-Feedback" ||
                            item.source === "Marketing-Puja-Suggestion" ? (
                            item?.sourcename &&
                            item.sourcename !== "" &&
                            item?.sourceid && (
                              <Col xs={7}>
                                <span className="aa">Puja Name :- </span>
                                <span className="item-msg">
                                  <Link
                                    to={`/shared-puja?pujaId=${item?.sourceid}`}
                                    // state={{ permissioncontext: permissioncontext }}
                                  >
                                    {item?.sourcename?.length <= 50
                                      ? item?.sourcename
                                      : `${item?.sourcename?.substring(
                                          0,
                                          40
                                        )}...`}
                                  </Link>
                                </span>
                              </Col>
                            )
                          ) : item.source === "Marketing-Reports-Inquiry" || 
                              item.source === "Leads-Reports-Inquiry" ? (
                            <Col xs={7}>
                              <span className="aa">Reports Name :- </span>
                              <span className="item-msg">
                                {item?.sourcename?.length <= 50
                                  ? item?.sourcename
                                  : `${item?.sourcename?.substring(0, 40)}...`}
                              </span>
                            </Col>
                          ) : null)}
                      </Row>

                      <Row className="notes-item">
                        <Col xs={9}>
                          <span className="item-name aa">Note :-</span>
                          <span className="item-msg"> {item?.message}</span>
                        </Col>

                        <Col xs={3}>
                          <div className="text-end d-flex flex-column">
                            <span className="text-primary item-name aa">
                              {item?.responderName || item?.adminName}
                            </span>
                            <span className="item-text">
                              {item?.createdAt
                                ? moment(item?.createdAt).format(
                                    "DD MMM, YYYY hh:mm A"
                                  )
                                : ""}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))}
              </>
            ) : (
              <>
                <h4 className="text-center text-secondary">Not Available</h4>
              </>
            )}
          </Modal.Body>
        </Modal>

        {/* user call confirmation */}
        <Modal
          show={showCallCm}
          size="sm"
          aria-labelledby="example-modal-sizes-title-sm"
          backdrop="static"
          onHide={handleCloseCallCm}
          centered
        >
          <Modal.Header className="modal-header-hd">
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="as_btn"
              onClick={(e) => handleConfirm()}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseCallCm}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </div>
    </div>
  );
};

export default ReportsInquiry;
